import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MAT_PAGINATOR_DEFAULT_OPTIONS } from '@angular/material/paginator';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from '@core/components/header/header.component';
import { CheckEnvironment, HasPermission, InfiniteScroller } from './directives';
import { MaterialsModule } from '@shared/materials/materials.module';
import { DayDatePipe } from '@shared/pipes/day-date/day-date.pipe';
import { AuthService } from '@modules/auth/services/auth.service';
import { DataService } from '@core/services';

@NgModule({
  declarations: [HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    MaterialsModule,
    NgOptimizedImage,
    RouterLinkActive,
    RouterOutlet,
    RouterLink,
    CheckEnvironment,
    InfiniteScroller,
    HasPermission,
    DayDatePipe,
  ],
  exports: [HeaderComponent, FooterComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (auth: AuthService, dataService: DataService) => {
        return async () => {
          const userId = await auth.initializeAuthUser();
          if (userId) {
            const res = await dataService.get(`admin/users/${userId}`, false);
            if (res.status_code === 200) {
              auth.setUserData(res.data);
            } else {
              auth.logout();
            }
          }
        };
      },
      deps: [AuthService, DataService],
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        pageSize: 50,
        pageSizeOptions: [50, 75, 100],
        showFirstLastButtons: true
      }
    }
  ],
})
export class CoreModule {
}
