import { DriverInfoModel } from "./driver-info.model";

export enum EDriverStatus {
  active = 'ACTIVE',
  pending = 'PENDING',
  inactive = 'INACTIVE',
  expired = 'EXPIRED'
}

export type TDriverStatus = `${EDriverStatus}`;

export class DriverModel {
  id: number;
  phone_number: string;
  email: string;
  first_name: string;
  last_name: string;
  type: string;
  status: TDriverStatus;
  image: string;
  created: string;
  verified: string;
  approved: string;
  employeeId: string;
  area_filter: string;
  geo_code?: string;
  driver: DriverInfoModel;
  device_type: 'ANDROID' | 'IOS';
  trip_count: number;
  rating: number;

  constructor(data: any) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.email = data.email;
    this.phone_number = data.phone_number;
    this.status = data.status;
    this.type = data.type;
    this.image = data.image;
    this.created = data.created;
    this.approved = data.approved;
    this.verified = data.verified;
    this.employeeId = data.employeeId;
    this.area_filter = data.area_filter;
    this.geo_code = data.geo_code;
    this.driver = new DriverInfoModel(data.driver);
    this.device_type = data.device_type;
    this.trip_count = data.trip_count;
    this.rating = data.rating;
  }

  get name() {
    return `${this.first_name} ${this.last_name}`.trim();
  }
}
